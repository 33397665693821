<template>
  <div class="mod-group">
    <div class="cell-header">
      <h4>旅客航段信息</h4>
      <span v-if="editable" style="font-size: 12px">(手续费、机建费、燃油费可以修改，修改后，系统自动计算应退款)</span>
    </div>
    <div class="cell-body">
      <a-table
        rowKey="id"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="orgDst" slot-scope="text">
          <div v-for="(segment, index) in text" :key="index">
            {{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}
          </div>
        </span>
        <template
          v-for="col in ['totalRefundSegmentFee', 'totalRefundSegmentAirportTax', 'totalRefundSegmentFuelTax']"
          :slot="col"
          slot-scope="text, record, index"
        >
          <div :key="col">
            <a-input
              v-if="editable"
              :value="text"
              type="number"
              @blur="e => onChange(col, e.target.value, index)"
              ></a-input>
            <span v-else>{{ text }}</span>
          </div>
        </template>
        <span slot="refundOrgDst" slot-scope="text">
          <div v-for="(segment, index) in text" :key="index">
            {{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}
          </div>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { airCity } from '@/filters/flight'
import { format } from '@/filters/time'

export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'passengerName'
        },
        {
          title: '票号',
          dataIndex: 'ticketNo'
        },
        {
          title: '航线',
          dataIndex: 'segmentInfos',
          scopedSlots: { customRender: 'orgDst' }
        },
        {
          title: '所退航段',
          dataIndex: 'refundSegmentInfos',
          scopedSlots: { customRender: 'refundOrgDst' }
        },
        {
          title: '机票价(所退航段的票价)',
          dataIndex: 'totalRefundSegmentNetFare'
        },
        {
          title: '手续费',
          dataIndex: 'totalRefundSegmentFee',
          scopedSlots: { customRender: 'totalRefundSegmentFee' }
        },
        {
          title: '机建费',
          dataIndex: 'totalRefundSegmentAirportTax',
          scopedSlots: { customRender: 'totalRefundSegmentAirportTax' }
        },
        {
          title: '燃油费',
          dataIndex: 'totalRefundSegmentFuelTax',
          scopedSlots: { customRender: 'totalRefundSegmentFuelTax' }
        },
        {
          title: '机票退款',
          dataIndex: 'totalRefundSegmentAmount'
        }
      ]
    }
  },
  props: {
    list: Array,
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange (col, value, index) {
      this.$emit('change', col, value, index)
    }
  },
  filters: {
    airCity,
    format
  }
}
</script>
