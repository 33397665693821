<template>
  <div class="page-b2b-refund-detail">
    <div class="loading" v-if="loading">
      <a-spin size="large"></a-spin>
    </div>
    <template v-else>
      <div class="mod-group mod-base">
        <div class="cell-header">
          <h4>基础信息</h4>
        </div>
        <div class="cell-body">
          <table class="table-infomation">
            <tr>
              <th>提交人:</th>
              <td>{{ refund.submitter }}</td>
              <th>提交人账户:</th>
              <td>{{ refund.submitAccount }}</td>
              <th>机票订单号:</th>
              <td>{{ data.orderNo }}</td>
            </tr>
            <tr>
              <th>退票类型:</th>
              <td>{{ refund.refundType | refundType }}</td>
              <th>申退时间:</th>
              <td>{{ refund.createTime }}</td>
              <th>退票单号:</th>
              <td>{{ refund.refundNo }}</td>
            </tr>
            <tr>
              <!-- <th>退票总额:</th>
              <td>{{ data.statisticInfo.totalActualAmount }}</td> -->
              <th>机票总额:</th>
              <td>{{ data.statisticInfo.totalNetFare }}</td>
              <th>机场建设费总额:</th>
              <td>{{ data.statisticInfo.totalAirportTax }}</td>
              <th>燃油费总额:</th>
              <td>{{ data.statisticInfo.totalFuelTax }}</td>
            </tr>
            <tr>
              <!-- <th>手续费:</th>
              <td>{{ data.statisticInfo.totalRefundFee }}</td> -->
              <!-- <th>燃油费总额:</th>
              <td>{{ data.statisticInfo.totalFuelTax }}</td>
              <th>附件:</th>
              <td>{{ data.attachmentPath || '无' }}</td> -->
            </tr>
            <tr>
              <th>提交退票时间距离航班起飞时间:</th>
              <td>
                <div v-for="segment in data.segments" :key="segment.id">
                  距离{{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}航班起飞: {{ segment.depTime | leftTime(refund.createTime) }}
                </div>
              </td>
              <th>起飞时间:</th>
              <td>
                <div v-for="segment in data.segments" :key="segment.id">
                  {{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}: {{ segment.depTime | format('YYYY-MM-DD HH:mm') }}
                </div>
              </td>
              <th>航段价格:</th>
              <td>
                <div v-for="segment in data.segments" :key="segment.id">
                  {{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}: {{ segment.netFare }}元 {{ segment.discount ? segment.discount + '%' : '' }}
                </div>
              </td>
            </tr>
            <tr>
              <th>清位时间:</th>
              <td>{{ refund.clearTime | format('YYYY-MM-DD HH:mm:ss') }}</td>
              <th>申请备注:</th>
              <td>{{ refund.descp }}</td>
              <th>退票单状态:</th>
              <td>{{ refund.status | refundStatus }}</td>
            </tr>
            <tr>
              <th>附件:</th>
              <td>
                <template v-if="refund.attachInfos && refund.attachInfos.length">
                  <a v-for="(item) in refund.attachInfos" :key="item.path" :href="item.path" target="_blank">{{item.fileName}}</a>
                </template>
                <span v-else>无</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="cell-footer">
          <span><label>团队出票量: </label>{{ data.totalLeftGroupQty }}</span>
          <span><label>退票后团队剩余人数: </label>{{ data.totalLeftGroupQty - data.totalRefundGroupQty }}</span>
          <span><label>政策要求最低人数: </label>{{ data.minGroupQty }}</span>
          <span><label>退票后，剩余人员占比整团人数: </label>{{ ((data.totalLeftGroupQty - data.totalRefundGroupQty) / data.totalLeftGroupQty * 100).toFixed(2) }}%</span>
        </div>
      </div>

      <refund-passenger v-if="data.airPassengerSegmentDTOS && data.airPassengerSegmentDTOS.length" :list="data.airPassengerSegmentDTOS"></refund-passenger>

      <!-- <admin-flow-table v-if="audits && audits.length" :list="audits"></admin-flow-table> -->
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import queryString from 'query-string'
import { fetchRefundOrderDetail } from '@/api/order'
import { refundType, refundStatus } from '@/filters/order'
import { airCity } from '@/filters/flight'
import { format } from '@/filters/time'
import { refundType as refundTypes } from '@/dicts/order'
import RefundPassenger from './components/refund-passenger'

import './css/refund-detail.less'

export default {
  data () {
    return {
      data: {},
      refund: {},
      loading: false,
      audits: null,
      confirmLoading: false,
      refundType: '',
      remark: '',
      refundTypes: refundTypes.slice(1),
      relevanceVisible: false
    }
  },
  created () {
    const params = queryString.parse(location.search)

    this.fetchDetail(params.id)
  },
  methods: {
    async fetchDetail (id) {
      this.loading = true

      try {
        const { bizData, auditHis } = await fetchRefundOrderDetail({
          refundId: id,
          serviceType: 'TICKET'
        })

        this.data = bizData
        this.refund = bizData.refund ? bizData.refund[0] : {}
        this.audits = auditHis
        this.loading = false
      } catch (err) {
        this.$message.error(err.message)
      }
    }
  },
  filters: {
    refundType,
    refundStatus,
    airCity,
    format,
    leftTime (value, createTime) {
      if (!value) {
        return ''
      }

      const time = (moment(value).valueOf() - moment(createTime).valueOf()) / 1000
      const day = Math.floor(time / (24 * 3600))
      const hour = Math.floor(time / 3600) - (day * 24)
      const minute = Math.floor(time / 60) - day * 24 * 60 - hour * 60

      return `${day ? day + '天' : ''}${hour}小时${minute}分钟`
    }
  },
  components: {
    RefundPassenger
  }
}
</script>
